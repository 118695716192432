@import './_type.css';
@import './_grid.css';
@import './_transitions.css';
@import './_calendar.css';
@import './_dropdown.css';
@import './_react_tags.css';
@custom-media --viewport-medium (width <=50rem);

:root {
  font-size: 10px;
  /* Colors */
  --col-text: #404555;
  --col-light-grey: #e3e3e5;
  --col-grey: #d6d7db;
  --col-grey-text: #8c8f98;
  --col-orange: #fc8b00;
  --col-blue: #45aaf2;
  --col-light-blue: #d4e9f7;
  --col-red: #ff003b;
  --col-dark-grey: #202535;
  --col-white: #d7d8db;
  --col-logo: #212635;
  /* Fonts */
  --oswald: 'Oswald', sans-serif;
  --merriweather: 'Merriweather', serif;
  --opensans: 'Open Sans', sans-serif;
  /* Spacing */
  --margin: 1.5%;
  --tile-margin: 1rem;
  --topbar-height: 6rem;
  --max-width: 1200px;
  color: var(--col-text);
  background: var(--col-light-grey);
  font-family: var(--opensans);
}

ul {
  list-style-type: none;
  padding: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
}

/* Placeholder settings  */

.placeholder {
  background-color: var(--col-text);
}

.settings {
  padding: 5%;
  text-align: right;
  width: 100%;
}

h4.placeholderEmpty {
  font: bold 4em var(--oswald);
  opacity: 0.25;
  margin-left: 5%;
  margin-right: 5%;
}

body.modal-is-open {
  overflow: hidden;
}

/* Buttons */

button,
.button {
  border: 0;
  cursor: pointer;
  background: transparent;
}

button:focus {
  outline: 0;
}

button:disabled:hover {
  text-decoration: none;
}

button:hover {
  text-decoration: underline;
}

.button {
  display: inline-block;
  background-color: var(--col-grey);
  padding: 0.75em 0.5em;
}

/* Forms */

label {
  margin-right: 1em;
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

::placeholder {
  /* Chrome/Opera/Safari */
  color: var(--col-grey);
}

/* Utilities */

.constrain {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-width);
  padding-right: var(--margin);
  padding-left: var(--margin);
  box-sizing: border-box;
  width: 100%;
}

.html-container ul,
.html-container ol {
  list-style-type: inherit;
  padding-left: 2em;
  font-size: 1.25em;
}

.html-container li {
  margin: 0 0 0.5em;
}

.html-container h1,
h2,
h3,
h4,
h5 {
  margin: 1em 0 0.5em;
}

:root .html-container h1 {
  font-size: 2.4em;
}

:root .html-container h2 {
  font-size: 1.8em;
}

:root .html-container h3 {
  font-size: 1.4em;
}

.html-container p {
  margin: 0 0 1em;
  line-height: 1.45em;
}

.html-container img {
  max-width: 100%;
  width: 100%;
}

.html-container figure {
  padding: 0;
  margin: 0;
}

.html-container a {
  color: var(--col-blue);
}

.html-container a:hover {
  text-decoration: underline;
}

.html-container blockquote {
  font-size: 1.6em;
  padding: 0;
  margin: 0;
  padding: 1em 0.5em 1em 2em;
  background: rgba(0, 0, 0, 0.1);
  border-left: 4px solid rgba(0, 0, 0, 0.25);
}

abbr[title] {
  text-decoration: none;
}

.body {
  margin: var(--topbar-height) 0 4em;
  min-height: 90vh;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid var(--col-text);
  cursor: pointer;
  display: inline-block;
  height: 0.75em;
  transition: background 0.25s;
  vertical-align: middle;
  width: 0.75em;
}

input[type='checkbox']:checked {
  background: var(--col-text);
}

/* ✊✊ Twitter!! */

:root .twitter-tweet {
  margin-top: 0 !important;
  width: 100% !important;
  min-width: 0 !important;
  display: flex !important;
  justify-content: center !important;
}

.twitter-tweet iframe {
  max-width: 200px !important;
}