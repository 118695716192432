.root {
  width: 100%;
}

.root img {
  display: block;
  width: 100%;
}

.lazy {
  visibility: hidden;
}

.lazy:global(.loaded) {
  visibility: visible;
}

.cropper {
  height: 0;
  position: relative;
  overflow: hidden;
  padding: 0;
}

.cropper img {
  display: block;
  position: absolute;
  left: 0;
  height: 100%;
  margin: 0;
  object-fit: cover;
  object-position: 50% 50%;
}

.cropper.vertical {
  object-position: 50% 0;
}