.root {
  --x-translate: 12px;
  --x-translate-neg: -12px;
  --menu-width: 20rem;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  height: var(--topbar-height);
  margin: 0 auto;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.flexContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
}

.logo h1 {
  align-items: center;
  font: bold 2em / var(--topbar-height) var(--merriweather);
  height: 100%;
  padding-left: 2%;
}

.logo h1 a {
  color: inherit;
}

.logo svg {
  display: block;
  height: 30px;
  fill: var(--col-logo);
  pointer-events: none;
  transition: fill 0.5s;
}

.menu {
  background-color: rgba(32, 37, 53, 0.95);
  box-shadow: inset 0 0 6em rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
  min-height: 100vh;
  padding-top: var(--topbar-height);
  position: absolute;
  top: 0;
  width: 100%;
}

.pagesMenu {
  color: var(--col-white);
  display: flex;
  font: 1.5em/1.5em var(--opensans);
  width: 100%;
}

.pagesMenu>div {
  min-width: 14em;
}

.pagesMenu h2, .pagesMenu a {
  color: inherit;
}

.pagesMenu h2 {
  font: 500 1em var(--oswald);
  color: var(--col-blue);
  padding: 1em 0 0 1.5rem;
  margin: 1em 0 0;

}

.pagesMenu div:first-child h2 {
  border: none;
}

.pagesMenu ul {
  padding: 0;
  margin: 0;
}

.pagesMenu a {
  color: white;
  display: block;
  padding: 0.75em 1.5rem 0.6em;
}

.pagesMenu a.active {
  color: var(--col-orange);
}

.pagesMenu a:hover {
  text-decoration: underline;
}

/* ===== Hamburger Menu ====== */

button.toggle {
  background: transparent;
  color: var(--col-text);
  height: var(--topbar-height);
  display: flex;
  font: 1.6em var(--oswald);

  margin-right: 0.75em;
  transition: transform 0.5s;
  z-index: 110;
}

button.toggle:hover {
  text-decoration: none;
}

button.toggle span {
  transition: opacity 0.3s, visibility 0s 0.3s;
}

.hamburger {
  margin-left: 0.5em;
  cursor: pointer;
  width: 22px;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0 0 0 0.75em;
}

.hamburger i {
  background-color: var(--col-text);
  content: '';
  display: block;
  width: 100%;
  height: 2px;
}

.hamburger i:nth-child(1) {
  animation: outT 0.8s backwards;
  animation-direction: reverse;
}

.hamburger i:nth-child(2) {
  margin: 10px 0;
  animation: outM 0.8s backwards;
  animation-direction: reverse;
}

.hamburger i:nth-child(3) {
  animation: outBtm 0.8s backwards;
  animation-direction: reverse;
}

/* ===== Menu chlidren ===== */

.row {
  border-top: 1px solid var(--col-text);
  display: flex;
  justify-content: space-between;
  padding: 2em 0;
}

.column {
  padding-top: 4em;
}

.foundation {
  display: none;
  justify-self: flex-end;
  margin-left: auto;
  margin-right: 0;
}

/* ===== Showmenu state ===== */

.showMenu .logo svg {
  fill: var(--col-white);
}

.showMenu button.toggle span {
  opacity: 0;
}

.showMenu .hamburger i {
  background: white;
}

.showMenu .hamburger i:nth-child(1) {
  animation: inT 0.8s forwards;
}

.showMenu .hamburger i:nth-child(2) {
  animation: inM 0.8s forwards;
}

.showMenu .hamburger i:nth-child(3) {
  animation: inBtm 0.8s forwards;
}

/* ===== Theme variants ===== */

.dark {
  background: var(--col-dark-grey);
  border-bottom: 0;
}

.dark svg {
  fill: white;
}

@media (min-width: 800px) {
  .foundation {
    display: inherit;
  }
}


/* ===== Animations ====== */

@keyframes inM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@keyframes outM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@keyframes inT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(var(--x-translate)) rotate(0deg);
  }
  100% {
    transform: translateY(var(--x-translate)) rotate(135deg);
  }
}

@keyframes outT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(var(--x-translate)) rotate(0deg);
  }
  100% {
    transform: translateY(var(--x-translate)) rotate(135deg);
  }
}

@keyframes inBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(var(--x-translate-neg)) rotate(0deg);
  }
  100% {
    transform: translateY(var(--x-translate-neg)) rotate(135deg);
  }
}

@keyframes outBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(var(--x-translate-neg)) rotate(0deg);
  }
  100% {
    transform: translateY(var(--x-translate-neg)) rotate(135deg);
  }
}