.enter {
  opacity: 0;
}
.enterActive {
  opacity: 1;
  transition: opacity 0.25s 0.5s;
}
.exit {
  opacity: 1;
}
.exitActive,
.exitDone {
  opacity: 0;
}
.enterDone {
  opacity: 1;
}
