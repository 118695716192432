.advancedFilterToggles {
  justify-content: flex-start;
}
.advancedFilterToggles > * {
  flex: 0;
}
.advancedFilterToggles button {
  background: transparent;
  border: 1px solid var(--col-grey-text);
  color: var(--col-grey-text);
  font: 1.4em/2em var(--oswald);
  margin-right: 0.4em;
  padding: 0.2em 0.5em;

}
.advancedFilterToggles button:hover {
  text-decoration: none;
}
.advancedFilterToggles > button {
  align-items: center;
  border: 1px solid transparent;
  display: flex;
  margin-right: 0;
}

.show .showFilterButtons button {
  opacity: 1;
  transform: translate(0, 0);
  visibility: visible;
}
.show .flippyDoubleArrow {
  transform: scaleX(-1);
}

.showFilterButtons {
  flex: 1;
}
.showFilterButtons button {
  opacity: 0;
  margin: 0 0.25em 0.5em;
  transform: translateY(-1em);
  transition: opacity 0.3s, transform 0.3s, visibility 0.3s, background 0.3s;
  transition-delay: 0.2s;
  visibility: hidden;
}
.showFilterButtons button:nth-child(1) {
  transition-delay: 0s;
}
.showFilterButtons button:nth-child(2) {
  transition-delay: 0.05s;
}
.showFilterButtons button:nth-child(3) {
  transition-delay: 0.1s;
}
.showFilterButtons button:nth-child(4) {
  transition-delay: 0.15s;
}
.showFilterButtons .selected {
  background: var(--col-text);
}

.flippyDoubleArrow {
  color: var(--col-blue);
  font: 1.5em var(--opensans);
  margin: -0.2em 0 0 0.3em;
  transition: transform 0.3s;
}
