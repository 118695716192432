.root {
  margin-bottom: 4rem;
}

.collectionEnd {
  height: 2px;
  width: 100%;
  background-color: transparent;
  height: 6rem;
}

.noResults {
  width: 100%;
  font: bold 3em/2em var(--oswald);
  text-align: center;

  color: rgba(0, 0, 0, 0.5);
}

.loadingBar {
  margin: 1em auto;
  display: block;
  height: 4rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.grid {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.column {
  padding: 0 0.75%;
  background-clip: padding-box;
  box-sizing: border-box;
}

:root .grid .column>* {
  margin-bottom: 2em;
  height: auto;
}