.root {
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*background-color: white;*/
  height: 100%;
}

.root > div {
  flex: 1;
}

.itemList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.itemList.width-2 li {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

button.readmore {
  width: 100%;
  background-color: var(--col-grey);
  color: var(--col-text);
  font-size: 1.5em;
  padding: 1em 0;
}

.imgContainer {
  padding: 0;
  margin: 0 0 1em;
}

.tweets li > * {
  border-top: 1px solid var(--col-grey);
}

.tweets li {
  margin: 0 1em;
}

.bg {
  background: white;
  height: 100%;
}