/**
 * <div class="react-tags">
 *   <div class="react-tags__selected">
 *     <button class="react-tags__selected-tag">
 *       <span class="react-tags__selected-tag-name" />
 *     </button>
 *   </div>
 *   <div class="react-tags__search">
 *     <div class="react-tags__search-input">
 *       <input />
 *       <div />
 *     </div>
 *     <div class="react-tags__suggestions">
 *       <ul>
 *         <li class="is-active">
 *           <mark />
 *         </li>
 *         <li class="is-disabled">
 *           <mark />
 *         </li>
 *       </ul>
 *     </div>
 *   </div>
 */
.react-tags {
  position: relative;
  border-radius: 1px;
  font-size: 1.8em;
  line-height: 2em;

  /* clicking anywhere will focus the input */
  cursor: text;

  border-bottom: 1px solid var(--col-grey);
  padding: 0;
  margin: 0 0 0.25em;
  transition: border-color 0.2s;
}

.react-tags.is-focused {
  border-color: var(--col-grey-text);
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  background: white;
  color: var(--col-grey-text);
  padding: 0.5em 0.5em;
  margin: 0 0.25em 0 0;
  transition: all 0.25s;
  font-size: 0.8em;
}

.react-tags__selected-tag:before {
  content: "\2715";
  margin: 0 0.4em 0 0;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: var(--col-blue);
  color: var(--col-blue);
  text-decoration: none;
  background: var(--col-light-blue);
}

.react-tags__search {
  display: inline-block;
  background: transparent;

  /* match tag layout */
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
  padding: 0.25em 0.75em;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;
  min-width: 16em;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;

  background: transparent;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 20;
  font-size: 0.8em;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid var(--col-grey);
  padding: 0.25em 0.75em;
  color: var(--col-grey-text);
}

.react-tags__suggestions li mark {
  color: var(--col-text);
  background: none;
  font-weight: bold;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}
