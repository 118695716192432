.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: white;
}

.header {
  align-items: center;
  display: flex;
  height: 6em;
  margin: 0 0 0 3%;
  justify-content: space-between;
}

.header i {
  font-size: 1.1em;
  margin: 0 0 0 0.25em;
  color: var(--col-grey-text);
  transition: color 0.25s;
}

.header a:hover i {
  color: var(--col-blue);
}

:root .root h2 {
  font-size: 1.8em;
  margin: 0;
}

.root>* {
  flex-wrap: wrap;
}

.timelineContainer {
  flex: 1;
  margin: 0 0 0 3%;
}

.root figure {
  display: none;
  margin: 0 0 0;
  flex: 1;
  position: relative;
}

@media (min-width: 750px) {
  .root {
    flex-direction: row;
  }

  .root figure {
    display: block;
  }

  .score {
    margin: 0;
  }
}