.root {
  margin: auto 0 0;
  background: var(--col-dark-grey);
  color: var(--col-grey);
  border-top: 1px solid var(--col-dark-grey);
  color: white;
}

.root a {
  color: white;
}

.root a.active, .root a:hover {
  text-decoration: underline;
}

.pagesMenu {
  display: flex;
  flex: 1;
  max-width: 52em;
  justify-content: space-between;
  margin-bottom: 2em;
}

.pagesMenu>div {
  font-size: 0.9em;
}

.pagesMenu ul {
  min-width: 14em;
}

.pagesMenu h2 {
  color: var(--col-blue);
  font-size: 1.8em;
  font-weight: 500;
}

.pagesMenu li {
  margin: 0.75em 0;
}

.pagesMenu a {
  color: var(--col-white);
  font-size: 1.5em;
}

.questions {
  background: var(--col-text);
  text-align: center;
  font-weight: 200;
  font-size: 1.75em;
  padding: 1.5em 5%;
}

.questions a {
  color: var(--col-blue);
  text-wrap: nowrap;
}

.columns {
  display: flex;
  margin: 4em auto 1em;
  padding: 0 var(--margin);
  justify-content: space-between;
  flex-wrap: wrap;
}

/* ====== Signup ======= */

.signup form {
  display: flex;
  margin-bottom: 3em;
}

.signup h3 {
  font-weight: 400;
  font-size: 1.6em;
  margin: 0 0 0.75em;
  display: block;
  color: white;
}

.signup input {
  flex: 1
}

.signup input[type='email'] {
  background: transparent;
  border: 1px solid var(--col-grey-text);
  padding: 0 1em;
  width: 14em;
  color: var(--white);
  line-height: 3em;
}

.signup input[type='email']::placeholder {
  color: var(--col-grey-text);
}

.signup input[type='submit'] {
  font: 500 1.8em/2.6em var(--oswald);
  padding: 0 0.9em;

  background: var(--col-blue);
  color: white;
  border: none;
  cursor: pointer;
}

/* ====== Ancillary ======= */

.ancillary {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  min-width: 32em;
}

.ancillary h3 {
  font-size: 1.5em;
  margin: 0 0 1em;
  color: white;
}

.ancillary p {
  margin: 0;
  color: var(--col-grey-text);
}

.ancillary a {
  color: inherit;
}

.shareIcons svg {
  margin: 0 0.5em 0 0;
  fill: var(--col-light-grey);
  padding: 0.1em;
  width: 25px;
  height: 25px;
  margin: 0 12px 0 0;
}

.copyright {
  display: flex;
  color: var(--col-blue);
  justify-content: space-between;
  font-size: 1.3em;
  border-top: 1px solid var(--col-grey-text);
  padding: 1em 0;
  max-width: calc(1200px - var(--margin) - var(--margin));
  margin: 3em auto 0.5em;
}

.copyright a {
  color: inherit;
}

@media (min-width: 800px) {
  .pagesMenu>div {
    font-size: inherit;
  }
}

@media (max-width: 1160px) {
  .copyright {
    padding-left: var(--margin);
    padding-right: var(--margin);
  }
}