.shade {
  border: 1px solid;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  z-index: 80;
}

.modal {
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  max-width: 80em;
  height: 75em;
  max-height: 90vh;
  overflow: hidden;
  position: fixed;
  z-index: 120;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
}

.body {
  overflow-y: auto;
  height: calc(100% - 10rem);
}

.top {
  display: flex;
  justify-content: space-between;
  padding: 0.1em 2.5%;
  align-items: center;
}

.closeBtn {
  transition: color 0.25s;
  font-size: 4rem;
  align-self: flex-end;
  margin-left: auto;
  padding-right: 0;
}

.closeBtn:hover {
  text-decoration: none;
  color: var(--col-grey);
}

.top h2 {
  font: bold 2.5em/1em var(--oswald);

  margin: 0;
}
