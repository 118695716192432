.root {
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
  position: relative;
}

.container:after {
  content: "";
  display: block;
  bottom: 0;
  height: 2em;
  width: 100%;
  position: absolute;
  z-index: 1;
  background: linear-gradient( to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

.scrollable {
  box-sizing: border-box;
  overflow-y: scroll;
  padding-right: 1em;
  transition: background 0.5s;
  width: 100%;
  max-height: 30em;
}

.root ul {
  margin-left: 1em;
  margin-right: 1em;
}

.root li {
  padding: 0.2em 0 2em 1.25em;
  position: relative;
  border-left: 2px solid var(--col-grey);
}

.root li:last-child {
  border-color: transparent;
}

.root li:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -6px;
  content: "";
  background: var(--col-dark-grey);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0 0 0 1px var(--col-grey);
  transition: all 0.5s;
}

.date {
  color: var(--col-blue);
  font-size: 1.2em;
}

.title {
  margin: 0.5em 0 0.5em;
  font-size: 1.5em;
}

.text {
  margin: 0;
}

.loadingContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadMore {
  width: 90%;
  background: var(--col-grey);
  color: var(--col-text);
  font-size: 1.25em;
  padding: 0.6em 0;
  margin: 0 auto 1em 10%;
  box-shadow: inset 0 0 0.25em rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
  transition: all 0.25s;
}

.loadMore:hover {
  text-decoration: none;
  background: var(--col-grey-text);
  box-shadow: inset 0 0 2em rgba(0, 0, 0, 0.1);
  color: white;
}

/* -- modifiers */

.scrolling .targeted:before {
  transform: scale(1.75);
  background: var(--col-orange);
}

@media (min-width: 750px) {
  .scrollable {
    height: 100%;
    position: absolute;
  }
}