.root {
  color: white;
  background: rgba(0, 0, 0, 0.4);
  font: 300 1.5em/1.5em var(--oswald);
  text-align: center;
  padding: 0;
  justify-content: center;
  padding: 0.15em 0;
  width: 100%;
}

/* Variants  */

.root.big {
  font: 300 2.75em/1.25em var(--oswald);
  padding: 0.65em 0;
}

.root.shadow {
  box-shadow: inset 0 0 1em rgba(0, 0, 0, 0.25);
}
