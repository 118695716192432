.root {
  height: 0;
  padding-bottom: 100%;
  position: relative;
  color: white;
  overflow: hidden;
}

.container {
  overflow: hidden;
  background-color: #ccc;
  height: 100%;
  display: flex;
  align-items: center;
}

.map {
  font-size: 0;
  width: 100%;
}

.map div,
.map a,
.map button {
  width: 10%;
  display: inline-block;
  padding: 0 0 10% 0;
  position: relative;
  font-size: 1.5rem;
  vertical-align: top;
  white-space: normal;
  text-align: left;
}

.land {
  background-color: #999;
}

.sea {
  background-color: #ccc;
}

.map span {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
}

/* Focused state */

.focused > div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
}

.focused h1 {
  top: 0;
  font: normal 3em var(--oswald);
}

.back {
  color: inherit;
  display: block;
  font-size: 1.25em;
  margin: 1em auto 0;
}

.back:hover {
  text-decoration: underline;
}

.root > svg {
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.8s 0s cubic-bezier(0.39, 0.2, 0.37, 1.08);
}

.root > svg a{
  filter: drop-shadow(2px 2px 0px rgba(0,0,0,0.75));
}

.root path {
  transition: fill 0.3s;
}

.children {
  height: 100%;
  width: 100%;
  z-index: 10;
  pointer-events: none;
}

.children a,
.children button {
  pointer-events: all;
  position: absolute;
  left: 0;
  padding: 0;
}

/* -- Label animations */

.root text {
  font: normal 45px/1em var(--oswald);
  transition: opacity 0.25s;
  user-select: none;
}

.labelsActiveExit {
  opacity: 0;
}

.labelsActiveEnter {
  opacity: 1;
}

.labelsDoneEnter {
  opacity: 1;
}

.labelsDoneExit {
  opacity: 0;
}

/* -- Zoom out button */

:root .zoomOutButton {
  display: block;
  fill: var(--col-text);
  width: 3.5em;
  background: rgba(255, 255, 255, 0.5);
  left: 0;
  padding: 0.75em 0.5em 0.5em 0.75em;
  z-index: 20;
}

/* -- Media queries */
@media (min-width: 600px) and (max-width: 819px) {
  .root {
    background: var(--col-grey);
    padding-bottom: 75%;
    width: 75%;
    margin: 0 auto;
  }
}
