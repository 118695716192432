.collapsible {
    margin: 3em 0 0
}

.map {
    margin: 0;
    position: relative;
    z-index: 3;
    background: var(--col-light-grey);
}

.euLink {
    overflow: hidden;
    display: block;
    position: relative;
}

.root .euLink p {
    position: absolute;
    z-index: 1;
    color: white;
    line-height: 2.8em;
    background: rgba(0,0,0,0.74);
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 0 0 0 1em;
    font-size: 1.8em;
}