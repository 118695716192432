/*
 * Modal 
 */

/* -- Initial */
.modal-enter .shade {
  opacity: 0;
}

.modal-enter .modal {
  opacity: 0;
  transform: translate(-50%, -55%);
}

/* -- Enter Done */
.modal-enter-done .modal,
.modal-enter-done .shade {
  opacity: 1;
}

.modal-enter-done .modal {
  opacity: 1;
  transform: translate(-50%, -50%);
}

/* -- Active */

.modal-enter-active .shade {
  opacity: 1;
  transition: opacity 0.3s;
}
.modal-enter-active .modal {
  opacity: 1;
  transition: opacity 0.3s, transform 0.3s;
  transform: translate(-50%, -50%);
}

/* -- Exit Done */
.modal-exit .modal {
  opacity: 1;
  transform: translate(-50%, -55%);
}
.modal-exit .shade {
  opacity: 1;
}

.modal-exit-active .modal {
  transition: opacity 0.3s, transform 0.5s;
  opacity: 0;
  transform: translateY(-50%, -50%);
}
.modal-exit-active .shade {
  transition: opacity 0.3s;
  opacity: 0;
}
