.credit {
    font-family: var(--oswald);
    font-size: 1.7em;
}

.credit p {
    margin: 0 0 0.35em;
}

.credit p:first-child {
    font-size: 1.3em;
}

.credit i {
    font-size: 1.2em;
    margin: 0 0 0 0.25em;
    display: inline-block;
    vertical-align: middle;
}

.credit a{
    color: var(--col-blue)
}