.root {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* display: flex;
  justify-content: space-between; */
}

.carousel {
  width: 100%;
  display: flex;
  transition: transform 0.3s;
}

.carousel > * {
  flex: 1;
}

button {
  padding: 0.4em;
  white-space: nowrap;
  color: var(--col-text);
}

button.active {
  text-decoration: underline;
}

.button:disabled {
  opacity: 0.3;
}

/* Controls */
.controls {
  list-style-type: none;
  margin: 2em auto 1em;
  padding: 0;
  display: flex;
  justify-content: center;
  font-size: 1.25em;
}

.controls button:disabled {
  color: var(--col-grey);
}

/* Progress bar variant */
.controls.progress {
  width: 100%;
  margin: 0 0 -8px;
  z-index: 1;
}

.progress button {
  text-indent: -999em;
  background: var(--col-grey);
  width: 100%;
  padding: 0;
  transition: background-color 0.3s;
  height: 8px;
}

.progress li {
  flex: 1;
  margin: 0 1px 0 0;
  overflow: hidden;
  height: 8px;
}
.progress li:last-child {
  margin-right: 0;
}

.progress button.active {
  background: var(--col-text);
}
