.root {
    background: white;
    font: bold 1.55em var(--merriweather);
    margin: 0.25em 0.25em 0 0;
    color: inherit;
    display: inline-block;
    padding: 0.35em 0.75em;
    transition: background 0.2s, color 0.2s;
}

.root:hover {
    text-decoration: none;
    background: var(--col-text);
    color: white;
}