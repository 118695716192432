/* ===== Pagination ===== */

.pagination {
  font-size: 1.25em;
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  z-index: 1;
  height: 8px;
}

.pagination button {
  text-indent: -999em;
  width: 100%;
  padding: 0;
  transition: border-color 0.3s;
  height: 100%;
  border-top: 8px solid var(--col-grey);
  background: transparent;
}

.pagination button:disabled {
  color: var(--col-grey);
}

.pagination li {
  flex: 1;
  margin: 0 1px 0 0;
  overflow: hidden;
}
.pagination li:last-child {
  margin-right: 0;
}

.pagination button.active {
  border-top: 8px solid var(--col-text);
}

/* ===== Tiny  ===== */

.pagination.tiny {
  height: 60px;
}

.pagination.tiny button {
  display: block;
  height: 60px;
  border: 0;
  background: transparent;
}

.pagination.tiny button:after {
  content: '';
  height: 3px;
  background: white;
  display: block;
  transition: background 0.25s;
}

.pagination.tiny button.active:after {
  background: var(--col-blue);
}

/* ===== NextPrevious ===== */

.nextPrevious {
  font-size: 1em;
  background: transparent;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  height: 2em;
  padding: 0;
  text-indent: -999em;
  transition: opacity 0.3s;
  width: 2em;
}

.nextPrevious:disabled {
  opacity: 0.2;
}

.next {
  transform: rotate(-45deg);
}

.previous {
  transform: rotate(135deg);
}

/* ===== NextPrevious Pagination2 carousel ===== */

:root .paginationTwo {
  display: flex;
  justify-content: flex-end;
  flex: 0;
}

.paginationTwo button {
  padding: 1em;
  background: white;
  margin-left: 2px;
}

.paginationTwo button:hover {
  background: var(--col-blue);
}
.paginationTwo button:hover .nextPrevious {
  border-color: var(--col-white);
}

.paginationTwo button:disabled {
  background: var(--col-grey);
}
.paginationTwo button:disabled .nextPrevious {
  border-color: var(--col-grey);
}

.paginationTwo .nextPrevious {
  border-right-color: var(--col-text);
  border-bottom-color: var(--col-text);
  font-size: 0.5em;
  border-width: 1px;
}

.paginationTwo .next {
  margin: 0 0.5em 0 -1em;
}

.paginationTwo .previous {
  margin: 0 -1em 0 0.5em;
}