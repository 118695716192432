.root {
  --filter-summary-height: 6em;
}
.textContainer {
  width: 100%;
}
.filtersRow {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
}
.filtersRow > * {
  flex: 1;
  margin: 0 0.25rem 1em;
}
.searchContainer {
  flex: 1 1 100%;
}
.keywordSearch {
  flex: 1 1 100%;
}
.dropdown {
  font-size: 1.4em;
}
.menuContainer {
  display: flex;
}
.placeholder {
  background: red;
  width: 2px;
}
.menu {
  background: var(--col-light-grey);
  width: 100%;
  z-index: 10;
  z-index: 60;
}
.menu.top {
  box-shadow: none;
}
.filterSummary {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0;
}
.filterSummary_large {
  box-sizing: border-box;
  font-size: 1.1em;
  margin: 1em auto;
  padding: 0 3em;
}
.filterSummary_large h4 {
  color: inherit;
}
.collectionContainer {
  margin-top: 2em;
  min-height: 400px;
}
.filterSummary_filters {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.filterSummary_filters div {
  font-size: 1.5em;
  margin: 0 1em 0.5em 0;
}
.resetButton {
  align-items: center;
  background: var(--col-dark-grey);
  color: var(--col-grey-text);
  display: flex;
  font: normal 1.4em var(--oswald);
  margin: 0 0.2em 0 0.1em;
  padding: 0.2em 0.6em 0.2em 0;

  transition: background 0.3s;
}
.resetButton i {
  font-size: 1.4em;
  margin: 0 0.25em;
}
.resetButton strong {
  font-weight: bold;

}
.filterSummary button:hover {
  text-decoration: none;
}
.resetButton:hover {
  background: var(--col-dark-grey);
}
.sectionsMenuSlot {
  align-items: center;
  display: none;
}
.menuToggle {
  background: white;
  height: 2.5em;
  margin-left: auto;
  padding: 0;
  right: var(--margin);
  width: 2.5em;
}
.menuToggle:hover {
  text-decoration: none;
}
.menuToggle:disabled {
  opacity: 0.1;
}
.itemsFound {
  color: var(--col-grey-text);
  font: normal 1.5em var(--oswald);
  margin: 0 0.5em 0 0;

}
.backToTop {
  background: var(--col-blue);
  border-radius: 50%;
  bottom: 1em;
  color: white;
  font-size: 3em;
  height: 1.8em;
  position: fixed;
  right: 1em;
  transition: background 0.3s;
  width: 1.8em;
  z-index: 200;
}
.backToTop:active {
  background: var(--col-light-blue);
}

.filtersPanel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.filtersPanel > * {
  flex: 0;
}

.trendingTerms {
  justify-self: flex-end;
  margin-top: auto;
  margin-bottom: 0;
}

.trendingTerms h3 {
  font: 1.65em/1.25em var(--oswald);

  color: var(--col-grey-text);
}

@media (min-width: 850px) {
  .sectionsMenuSlot {
    display: flex;
  }
  .backToTop {
    display: none;
  }
}
