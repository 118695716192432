.root {
  display: flex;
  background: white;
  margin: 0 0 1px;
  transition: background 0.3s, color 0.3s;
}

.root>div {
  flex: 1;
  display: flex;
  align-items: left;
  padding: 0 1.25em 0 1em;
  font: bold 1.5em/1.25em var(--merriweather);
  flex-direction: column;
  justify-content: center;
}

.root>div:first-child {
  flex: 0 0 30%;
  padding-left: 0;
  padding-right: 0;
}

.subhead {
  font: normal 0.75em/1.6em var(--oswald);
  color: var(--col-blue);
  margin: 0;
  width: 100%;
  margin: 0 0 0.5em;
}

/* Variants  */

.root.big {
  margin: 0 0 0.5em;
}

.anchored {
  color: inherit;
  display: block;
  transition: color 0.3s;
}

.anchored:hover .root, .anchored:focus .root {
  background: var(--col-blue);
  color: white;
}