.root {
  max-width: var(--max-width);
  margin: 2em auto 0;
  padding-left: calc(var(--margin) * 1.25);
  padding-right: var(--margin);
  padding-bottom: 0.5em;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: baseline;
}

.root a {
  color: var(--col-blue);
}

.root a:hover {
  color: var(--col-blue);
}

.collapsible {}

.collapsible>* {
  transition: opacity 0.5s;
  max-height: auto;
}

.collapsed>* {
  opacity: 0;
}

.root h1 {
  margin: 0 0 0.25em;
}

@media (min-width: 800px) {
  .root {
    display: flex;
    min-height: 8em;
    align-items: flex-end;
    margin-top: 0;
  }

  .root h1 {
    margin: 0;
  }
}