.enterActive,
.enterDone,
.exitActive {
  position: sticky;
  top: var(--topbar-height);
  /* border-bottom: 1px solid var(--col-grey);
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.25); */
}

.enter {
  transform: translateY(-100%);
}

.enterActive {
  transform: translateY(0);
  transition: transform 0.5s;
}

.exit {
  transform: translateY(0);
}
.exitActive {
  transform: translateY(-100%);
  transition: transform 0.5s;
}
