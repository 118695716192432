.root {
    min-height: 100vh;
    margin-top: var(--topbar-height);
    background: var(--col-dark-grey);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.root h1 {
    color: var(--col-light-grey);
    font-size: 3.2em;
    position: relative;
    text-align: left;
    max-width: 12em;
}

.img {
    background-image: url("/404_periscope.png");
    background-size: 100%;
    background-position: center center;
    width: 100%;
    height: 0;
    padding: 0 0 50%;
    display: block;
    box-shadow: inset 0 0 5px 0 var(--col-dark-grey);
}