.root {
  position: relative;
}

.pageheader {
  margin-bottom: 1em;
}

.centered {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font: 2em/1.5em var(--merriweather);
}

.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4em;
  flex-wrap: wrap;
}

.text {
  font-size: 1.25em;
  flex: 1 1 100%;
  max-width: 55em;
}

.imgContainer {
  flex: 1 1 100%;
}

button.back {
  background: transparent;
  white-space: nowrap;
  display: flex;
  font: 1.75em/1em var(--oswald);
  align-items: center;
  margin-left: auto;
  transition: color 0.3s;
}

button.back:hover {
  text-decoration: none;
  color: var(--col-blue);
}

button.back i {
  font-size: 1.2em;
  margin: 0 0.15em 0 0;
}

@media (min-width: 780px) {
  .container {
    flex-direction: row-reverse;
  }

  .text {
    flex: 1;
    margin-right: 6em;
  }

  .imgContainer {
    flex: 0 0 28em;
  }
}

@media (min-width: 800px) {
  .text {
    flex: 1;
    margin-right: 6em;
  }

  .imgContainer {
    flex: 0 0 36em;
  }
}
