.Dropdown-root {
  position: relative;
  transition: background 0.3s;
  box-shadow: inset 0 0 0 1px var(--col-grey-text);
  color: var(--col-grey-text);
  font: 1em/1.25em var(--oswald);
}

.Dropdown-menu {
  background: white;
  position: absolute;
  z-index: 100;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  margin: 0.75em 0 0 0;
  top: 1.75em;
  min-width: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.Dropdown-control {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  padding: 0.5em 0.75em;
}

.Dropdown-arrow-wrapper i {
  display: block;
}

.Dropdown-option {
  background: white;
  cursor: pointer;
  padding: 0.5em 0.5em;
  box-sizing: border-box;
  width: 100%;
  border-top: 1px solid var(--col-grey);
  transition: background 0.3s, border-color 0.3s;
}

.Dropdown-option:hover {
  border-top: 1px solid var(--col-blue);
  background: var(--col-light-blue);
}

/* -- is-open variant */

.is-open {
  background-color: var(--col-text);
}

.is-open .Dropdown-arrow-wrapper {
  border-color: white;
}

/* -- Big variant */

.Dropdown-root.big {
  display: inline-block;
  background-color: transparent;
  padding: 0;
  margin: 0 0 0 0.25em;
  font: bold 1.5rem var(--merriweather);
  box-shadow: none;
  font-size: 1em;
  text-transform: none;
  color: inherit;
}

.big .Dropdown-arrow-wrapper {
  margin-left: 0.25em;
}

.big .Dropdown-arrow-wrapper i {
  font-size: 1em;
}

.big .Dropdown-control {
  justify-content: start;
  align-content: center;
  align-items: center;
  padding: 0;
}

.big .Dropdown-menu {
  font: bold 1.5rem var(--merriweather);
  display: flex;
  max-width: 50em;
  flex-wrap: wrap;
  top: 3.25em;
  right: 0;
  width: 30em;
}

.big .Dropdown-option {
  flex: 0 1 33%;
  margin: 0 0.5% 0 0;
}

.big .Dropdown-option:nth-child(3n+0) {
  margin: 0
}

.big.is-open .Dropdown-placeholder {
  color: inherit;
}

@media screen and (min-width: 800px) {
  .big .Dropdown-menu {
    right: unset;
    left: 0.5em;
  }
}