.root {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
  background: rgba(32, 37, 53, 0.85);
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 9.5%;
  z-index: 20;
}

.label {
  font: 1.3em/1em var(--oswald);

  margin: 0 0.5em;
}

.color {
  display: flex;
  height: 1.6em;
}

.color div {
  width: 1.5em;
}
