.masonryContainer {
  margin-top: 1.5em;
}

.timeline {
  height: 100%;
  background: white;
  padding: 0 0 0 0.5em;
}

.credit {
  font-size: 1.1em
}