.root {
  height: 100%;
  background-color: white;
  --text-padding: 1rem;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.root > a:before {
  transform: scaleX(0);
  content: "";
  display: block;
  background-color: var(--col-blue);
  width: 100%;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 6;
  transition: transform 0.4s;
}

.root > a:hover {
  z-index: 2;
}

.root > a:hover:before {
  transform: scaleX(1);
}

.imgContainer {
  margin: 1em 0 0;
}

.readMoreOverlay i {
  font-size: 4em;
  line-height: 1em;
}

h3.title {
  font: 600 1.25em/1.4em var(--merriweather);
  transition: color 0.3s;
  word-break: break-word;
  margin: 0em;
}

.root > a {
  display: block;
  color: inherit;
  transition: background 0.3s;
  cursor: pointer;
}

.root h4 {
  font: normal 1.3em var(--oswald);
  color: var(--col-orange);
}

.root h4 > * {
  flex: 1;
}

.root h4 a {
  color: var(--col-text);
}

.root h4 a:hover {
  text-decoration: none;
  color: var(--col-blue);
}

.root {
  border-bottom: 1px solid var(--col-light-grey);
}

.root p {
  font-size: 1.3em;
  line-height: 1.5em;
}

.root:last-child {
  border-bottom: 0;
}

.root > * a {
  color: var(--col-blue);
  transition: color 0.3s;
}

.root > * a:hover {
  color: var(--col-blue);
}

.root h5 {
  font-size: 1.15em;
  margin: 0;
  word-break: break-word;
}

.columnContainer,
a.columnContainer {
  display: flex;
  justify-content: space-between;
}

.columnContainer > * {
  flex: 12;
}

a.readMore {
  margin: 0.5em 0 0;
  text-align: right;
  white-space: nowrap;
}

.source {
  padding: 0.4em var(--text-padding) 0.25em;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  font: 1.1em var(--oswald);
  margin: 0;
  flex-wrap: wrap;
}

.source h5:first-child {
  flex: 1;
  margin-right: 1em;
}

.source h5 {
  margin: 0;
  display: flex;
  align-items: center;
}

.source h5 > * {
  align-content: center;
}

.source img {
  width: 16px;
  margin: 0 5px 0 0;
  filter: grayscale(100%);
  font-size: 0;
}

.content {
  padding: 0 var(--text-padding);
}

div.thumbnail {
  padding: 0;
  width: 30%;
  float: right;
  margin: 0 0 1em 1.25em;
}

.root .tags {
  font-size: 1.1em;
  color: var(--col-grey-text);
  margin: 0.5em 0 0;
}

.documentTagIcon {
  width: 2em;
  margin: 0 0.25em 1em 0;
}

/* -- Size 0 -- */

.size-0 .content {
  border-top: 1px solid var(--col-grey);
  padding: 1.5em 0 0;
  margin: 0 var(--text-padding);
}

.size-0 h5 {
  font: normal 1.25em/1.5em var(--oswald);
  color: var(--col-text);
}

.size-0 .provider {
  margin-bottom: 0.75em;
}

.size-0 p {
  font-size: 1.2em;
}

.size-0 .textContainer {
  display: inline;
}

.size-0 .wide .dateline {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  margin: 0;
}

.size-0 .wide h5 {
  margin: 0 0 0.5em;
}

.size-0 .wide .content {
  display: flex;
}

.size-0 .wide .thumbnail {
  flex: 1 0 30%;
  float: none;
}

/* -- Size 1 -- */

.size-1 .title {
  margin: 0 0 0.5em;
}

/* -- Size 2 -- */
.size-2 {
  background: white;
  height: 100%;
}

.size-2 .tags {
  margin-top: 0.75em;
}

.size-2 .title,
.size-2 h4,
.size-2 p {
  padding: 0 var(--text-padding);
}

.size-2 p {
  margin-bottom: 2em;
}

.size-2 .title {
  margin-top: 0.25em;
  font-size: 2em;
}
.size-2 h5 {
  font: normal 1.25em var(--oswald);
}

.size-2 .imgContainer {
  margin: 0;
  position: relative;
}

.size-2 .source {
  margin-bottom: 0;
}
.size-2 .title {
  margin-top: 1em;
  font-size: 1.75em;
}
.size-2 .title a {
  color: var(--col-black);
}
.size-2 .title a:hover {
  color: var(--col-blue);
}
.size-2.isImage .source {
  display: flex;
  bottom: 0;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
}

.size-2:before {
  height: 3px;
}

.size-2:hover:after {
  opacity: 0;
}

/* -- Size 3 */

.size-3 {
  color: white;
}

.root.size-3 > *,
.root.size-3 > a:hover {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 22.5% calc(100% - 45em) 1em 1.5em;
  box-shadow: inset 0 -8em 45em var(--col-dark-grey);
  box-sizing: border-box;
  z-index: auto;
}

.size-3 > a:before {
  height: 3px;
}

.size-3 .source {
  color: white;
}

.size-3 .imgContainer {
  margin: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  display: flex;
  align-items: center;
}

.size-3 .textContainer {
  color: white;
  padding: 0.4em var(--text-padding) 0.25em;
  font-size: 1.2em;
}

.size-3 h3 {
  font-size: 1.8em;
}

.size-3 p {
  margin-top: 0.25em;
}

@media (min-width: 800px) {
  .root.size-3 > *,
  .root.size-3 > a:hover {
    padding-top: 0;
  }
}
