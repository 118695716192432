.root {
  --line-height: 3.8rem;
  flex: 0;
  position: relative;
  width: 30em;
}
.controls {
  display: flex;
  justify-content: flex-start;
}
.controls button {
  align-items: center;
  background: transparent;
  box-shadow: inset 0 0 0 1px var(--col-grey-text);
  color: var(--col-grey-text);
  display: flex;
  font: 1.4em / var(--line-height) var(--oswald);
  justify-content: space-between;
  padding: 0 0.75em;

  transition: color 0.3s, background 0.3s;
  width: 100%;
}
.controls button i {
  font-size: 1.5em;
}
.controls button > * {
  margin: 0 0.25em;
}
.controls button:hover {
  background-color: var(--col-text);
  text-decoration: none;
}
button.clear {
  color: var(--col-text);
  flex: 0;
}
.calendars {
  font-size: 1.25em;
  height: 0;
  max-height: 0;
  max-width: 25em;
  overflow: hidden;
  right: 0;
  transition: max-height 0.3s;
  z-index: 10;
}
.showCalendars .calendars {
  height: auto;
  max-height: 40em;
}
button.indicator:hover,
.showCalendars button.indicator {
  background-color: var(--col-text);
}
button.indicator.active {
  background: white;
  color: var(--col-text);
}
button.indicator.active:hover {
  background: var(--col-blue);
}
.tabs {
  display: flex;
  font: 1.25em/2em var(--oswald);
  overflow: hidden;
}
.tabs > * {
  background: var(--col-grey);
  color: var(--col-grey-text);
  flex: 1;

}
.activeTab {
  background: white;
  color: var(--col-blue);
}
.tabs button:hover {
  color: var(--col-blue);
  text-decoration: none;
}
ul.customDates {
  border-bottom: 1px solid var(--col-grey);
  display: block;
  margin: 0;
  font: bold 1.1em var(--oswald);

  color: var(--col-grey-text);
}

@media (min-width: 800px) {
  .calendars {
    position: absolute;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.25);
  }
}
