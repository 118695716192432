.root {
  align-items: center;
  color: white;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  position: relative;
  text-align: center;
  width: 100%;
}

.root>div {
  max-width: 90em;
}

.flexContainer {
  display: flex;
  align-items: center;
}

.flexContainer__wide {
  flex: 1;
  overflow: hidden;
}

.flexContainer__narrow {
  flex: 0 0 20%;
  overflow: hidden;
}

.videContainer {
  background: var(--col-dark-grey);
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  padding: 0;
  top: 0;
  z-index: -1;
}

.videContainer video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  left: 0;
  top: 0;
  opacity: 0.2;
}

/* ====== Header ====== */

.heading {
  font-size: 1em;
  margin: 0 0 5em;
  text-align: center;
}

.heading>* {
  display: block;
}

.heading__med {
  font: normal 3em/1.5em var(--oswald);

}

.heading__small {
  font: normal 1.6em / 1.75em var(--merriweather);
  font-style: italic;
  margin: 0 0 0.5em;
}

.heading__logo {
  fill: white;
  margin: 0 auto;
  max-width: 45em;
  width: 90vw;
}

/* ====== Slides ====== */

.slide {
  font-size: 1.1rem;
}

.slide h2 {
  font: bold 2.25em / 1.25em var(--merriweather);
  margin: 0 0 0.5em;
  text-align: center;
}

.slide p {
  font: normal 1.4em / 1.4em var(--opensans);
  margin: 0 0 1em;
  text-align: center;
}

/* ====== Controls ======*/

.pagination {
  margin: 0 auto 2em;
  width: 35%;
}

.nextPrevious {
  font-size: 1.5em;
  margin: 1em;
}

/* ====== Form ======  */

.continue {
  background: transparent;
  border: 1px solid white;
  color: white;
  font: normal 1.75em / 3.5em var(--oswald);
  padding: 0 2em;

  transition: background 0.25s;
  margin: 0 auto 1.5em;
}

.continue:hover {
  text-decoration: none;
  background: white;
  color: var(--col-dark-grey);
}

.root input[type='checkbox'] {
  border: 1px solid white;
  margin: 0 0.6em 0 0;
  height: 1em;
  width: 1em;
}

.root input[type='checkbox']:checked {
  background: var(--col-blue);
}

.dontShowLabel {
  display: inline-block;
  font: normal 1em/1.75em var(--oswald);
  margin: 0 auto;

  vertical-align: middle;
}

.cookieNotify {
  font-style: italic;
  font-size: 1.6em;
  max-width: 30em;
  margin: 1em auto;
}

/* ====== Foundation logo ======  */

.foundation {
  fill: white;
}

.submenu {
  z-index: 110;
}