.root {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 1.25em;
  position: relative;
  --line-height: 6rem;
  --button-width: 8rem;
}

.root > * {
  margin-top: 1em;
}

.root form {
  display: flex;
  min-width: 30em;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  margin: 0;
}

.root input[type='text'] {
  font-size: 1.3em;
  line-height: var(--line-height);
  height: var(--line-height);
  border: none;
  background-color: transparent;
  width: 50%;
  min-width: 18em;
  box-sizing: border-box;
  transition: border 0.3s;
  flex: 1;
  transition: box-shadow 0.3s;
  border-bottom: 1px solid var(--col-grey);
  padding: 0 var(--button-width) 0 1em;
}

.root input[type='text']:focus {
  outline: none;
  background: transparent;
}

.submit {
  align-items: center;

  color: var(--col-grey);
  font: 1em / var(--line-height) var(--oswald);
  border: none;
  transition: opacity 0.3s;
  color: var(--col-text);
}

.active {
  background: var(--col-text);
  color: white;
}

.root button {
  background: transparent;
  display: flex;
  width: var(--button-width);
  transition: color 0.3s;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  justify-content: center;
}

.root button i {
  font-size: 2.25em;
}

.root button:hover {
  text-decoration: none;
  color: var(--col-blue);
}

form:invalid .submit {
  opacity: 0.2;
}

.root button:disabled {
  display: none;
}

.root ul {
  font-size: 1.3em;
  display: flex;
  padding: 0;
  align-items: center;
  margin-bottom: 0;
  flex-wrap: wrap;
}

.root ul:first-child {
  font-size: 1.75em;
}

.clearButton {
  align-items: center;
  background: transparent;
  color: var(--col-text);
  transition: color 0.3s;
}

.clearButton:disabled {
  visibility: hidden;
}

.root h3 {
  margin: 0.25em 0 0;
  font-size: 1.5em;
}

/* Variants */

.root.primary input[type='text'] {
  padding: 2em var(--button-width) 2em 1em;
  border-bottom: 0;
  font-size: 1.5em;
}

.root.primary .submit {
  font-size: 1.4em;
}

.root.primary .clearButton {
  font-size: 1.4em;
}

.root.primary form {
  background: white;
}

.root.primary input[type='text']:focus {
  box-shadow: inset 0 0 6px var(--col-light-blue);
}
