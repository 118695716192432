.root {
  display: flex;
  flex-wrap: wrap;
  padding: 0 2.5%;
}

.root h1 {
  flex: 1 100%;
  font-size: 2.75em;
  margin: 0 0 1em;
}

.columnAncillary,
.columnRight {
  flex: 1;
  margin: 0 0 4em 5%;
}

:root .columnMain p {
  font-size: 1.4em;
  line-height: 1.5em;
}

.readMore {
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 1.5em;

  background: var(--col-blue);
  color: white;
  padding: 1em 0;
  justify-content: center;
}

.readMore svg {
  width: 1.25em;
  margin: 0 0 0 0.5em;
  fill: white;
}

.columnMain header {
  display: flex;
  justify-content: space-between;
}

.columnMain header h3 {
  font: 1.6em var(--oswald);
  max-width: 16em;
}

.columnMain header h3 a {
  color: var(--col-blue);
}

.columnMain header h3 {
  font-size: 1.6em;
}

.dateline {
  font-size: 1.25em;
  margin: 0 0 2em;
}

.related {
  margin: 3em 0 0;
}

.related h5 {
  font: 1.4em var(--oswald);

}

.related ul {
  font-size: 1.2em;
  margin: 0.5em 0 0;
}

.related li {
  margin: 0.75em 0 0;
}

.related a {
  color: var(--col-blue);
}

@media (min-width: 600px) {
  .columnAncillary {
    flex: 0 0 25%;
    padding-top: 6em;
  }

  .columnMain {
    flex: 1 0 70%;
  }
}
