@lost flexbox flex;
@lost gutter 20px;
.grid > * {
  padding: 0 0 2em;
}

.grid {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.grid > * {
  box-sizing: border-box;
  padding: 0.5em;
}

.grid > *,
.grid--item,
.grid--item__quarter,
.grid--item__third {
  width: 100%;
}

/* @media (min-width: 550px) {
  .grid>*, .grid--item, .grid--item__quarter {
    width: 50%;
  }
  .grid--item__third {
    flex: 0.5 0 100%;
  }
  .grid--item__two-thirds {
    width: 100%;
  }
  .grid--item__half {
    width: 100%;
  }
} */

@media (min-width: 820px) {
  .grid > * {
    padding: 0.75%;
    flex: 0 1 auto;
  }
  .grid > *,
  .grid--item,
  .grid--item__quarter {
    width: 25%;
  }
  .grid--item__third {
    width: 33.333333%;
    flex: auto 0;
  }
  .grid--item__two-thirds {
    width: 66.66666666%;
  }
  .grid--item__half {
    width: 50%;
  }
  .grid--item__full {
    width: 100%;
  }
}
