.root {
    font: bold 1.5em var(--oswald);

    padding: 0.75em var(--tile-margin) 0.25em;
    display: flex;
    align-items: center;
}

.icon {
    height: 1.25em;
    fill: var(--col-blue);
    margin: 0 0.5em 0 0;
}