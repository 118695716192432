.subhead {
    font: bold 1.5em var(--oswald);

    margin: 0 0 0.5em
}

.subhead.secondChild {
    margin-top: 2em;
}

.column {
    transition: box-shadow 0.3s 0.15s, background 0.3s 0.15s;
}

.singleItem {
    height: auto;
    margin: 0 0 1em
}

:root .singleItem>a>div {
    border-top: 0;
}

:root .bigFigure {
    margin: 0 0 0.75em
}

.collapsible {
    margin: 3em 0 0
}