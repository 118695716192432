.root {
  margin-bottom: 2em;
}

.headerImage {
  height: 0;
  padding-bottom: 60%;
  background-size: cover;
  margin-top: -7em;
  z-index: -1;
  position: relative;
  background-color: var(--col-text);
  box-shadow: inset 0 0 6em rgba(0, 0, 0, 0.5);
}

.storyTile {
  flex: 1;
  height: 100%;
}

.firstRow {
  margin: 0;
}

.firstRow > * {
  padding: 0 1% 1%;
}

.firstRow > *:last-child {
  padding-right: 0;
}
.loadingSpinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em auto 0;
}

@media (min-width: 680px) {
  .headerImage {
    padding-bottom: 50%;
  }
}

@media (min-width: 1000px) {
  .headerImage {
    padding-bottom: 40%;
  }
}

@media (min-width: 1200px) {
  .headerImage {
    padding-bottom: 30%;
  }
}
