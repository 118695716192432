.root {
  height: 100%;
  /* max-height: 80vh; */
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.root > div {
  text-align: center;
}
