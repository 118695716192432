.root {
  background: var(--col-light-grey);
  margin: auto;
  width: 100%;
}

.container {
  align-items: center;
  display: flex;
  font-size: 0.9rem;
  padding: 0;
  position: relative;
  width: 96%;
}

.root .container>p {
  display: none;
  font: 1.6em var(--oswald);

  margin: 0 0.75em 0 0;
  color: var(--col-grey-text);
}

.root h1 {
  display: none;
  font: bold 2.2em var(--merriweather);
  margin: 0 0 0 0.65em;
}

.root ul {
  display: flex;
  width: 100%;
  flex: 0;
}

.root li {
  margin-left: 0;
}

.root a {
  align-items: center;
  display: flex;
  white-space: nowrap;
  font: normal 1.6em var(--oswald);
  padding: 0.4em 0.75em;
  color: var(--col-text);
  border: 1px solid var(--col-light-grey);
  transition: all 0.3s;
  background: white;
  margin: 0 -1px 0 0;

}

.root a svg {
  fill: var(--col-text);
  height: 1.25em;
  margin-right: 0.4em;
}

.root a.active {
  background: var(--col-dark-grey);
  color: var(--col-grey);
}

.root a.active svg {
  fill: var(--col-grey-text);
}

.right {
  margin-left: var(--margin);
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.root .highlight,
.root .highlight.active {
  background: white;
  position: relative;
  z-index: 110;
}

.root .highlight:before {
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 0.25em white;
  content: "";
  height: 6em;
  left: 50%;
  opacity: 0.25;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 6em;
  z-index: -1;
}

/* ===== Theme variants ===== */

.dark {
  background: var(--col-dark-grey);
  color: white;
}

.dark :global(.Dropdown-arrow-wrapper) {
  background: var(--col-dark-grey);
}

.dark :global(.Dropdown-arrow-wrapper) i {
  color: var(--col-white);
}

.dark a {
  background: var(--col-dark-grey);
  border-color: transparent;
}

/* ===== Sticky ===== */

.root.sticky {
  position: sticky;
  top: var(--topbar-height);
  z-index: 90;
}

.root.sticky:after {
  content: "";
  display: block;
  width: 100%;
  height: 10px;
  position: absolute;
  bottom: -10px;
  left: 0%;
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0.15) 0%,
      rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.5s;
}

.top.sticky:after {
  opacity: 0;
}

@media (min-width: 800px) {

  .container {
    padding: 0 2%;
  }

  .root h1 {
    display: inline-block;
  }

  .root .container>p {
    display: block;
  }
}