.langDd {
  display: inline-block;
  margin: 0 0 0 1em;
  font: 1.25em/1.25em var(--oswald);

  box-shadow: none;
}

.langDd:global(.is-open) {
  background: transparent;
}

.langDd img {
  width: 28px;
}

.langDd :global(.Dropdown-control) {
  padding: 0 0 0 1em;
}

.langDd :global(.Dropdown-arrow-wrapper) {
  background: transparent;
  color: var(--col-text);
}

.langDd :global(.Dropdown-option) > div,
.langDd :global(.Dropdown-placeholder) > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.langDd :global(.Dropdown-menu) {
  right: 0;
}

.langDd :global(.Dropdown-option) > div {
  width: 9em;
}

.langDd :global(.Dropdown-placeholder) > div span {
  display: none;
}

.langDd :global(.Dropdown-placeholder) {
  margin: 0 0.75em 0 0;
}
