.root {
  display: flex;
  flex-direction: column;
}

.root > article {
  flex: 1;
}

.root h2 {
  font-size: 1.4em;
}

.root p {
  font-size: 1.2em;
}

.contentContainer {
  transition: height 0.5s;
  overflow: hidden;
}
