:root .react-calendar {
  width: 28rem;
  border: none;
  font-family: var(--opensans);
  padding: 0.25em;
}

:root .react-calendar__tile:enabled:focus,
:root .react-calendar__tile.react-calendar__tile--active {
  background: var(--col-blue);
  color: white;
}

:root .react-calendar button {
  transition: background 0.3s, color 0.3s;
}

:root .react-calendar__navigation button:enabled:focus,
:root .react-calendar__navigation button:enabled:hover {
  background: transparent;
  text-decoration: none;
  color: var(--col-blue);
}

:root .react-calendar__tile {
  border-radius: 50%;
}

:root .react-calendar__tile:hover {
  text-decoration: none;
  cursor: pointer;
}

:root .react-calendar__month-view,
:root .react-calendar__month-view button {
  color: var(--col-grey-text);
}

:root
  .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--col-grey);
}

:root .react-calendar__month-view__days__day {
  padding: 0.25em;
  line-height: 2.25em;
}

:root .react-calendar__month-view__days__day abbr {
  text-decoration: none;
}

:root .react-calendar__month-view__days__day--weekend {
  color: inherit;
}

:root .react-calendar__month-view__weekdays {
  font-size: 1em;
  color: var(--col-grey);
}

:root .react-calendar__navigation {
  font-size: 1.75em;
  margin: 0;
  padding: 0.5em 0 0.5em;
  align-items: center;
}

/* top nav */
:root .react-calendar__navigation button {
  font-weight: bold;
  padding: 0;
}

:root .react-calendar__navigation button:hover {
  background: transparent;
}

/* the year/month header */
:root button.react-calendar__navigation__label {
  font-size: 0.9em;
  color: var(--col-text);
}

:root .react-calendar__month-view {
  font: 1.1em var(--oswald);
}

/* Year view */
:root .react-calendar__tile.react-calendar__year-view__months__month {
  border-radius: 0;
}

/* Disabled buttons */

:root .react-calendar__navigation button:disabled {
  background: transparent;
  color: var(--col-light-grey);
}

:root .react-calendar__tile:disabled {
  background: transparent;
  color: var(--col-light-grey);
}
